import { useTranslation } from '@elzeard/common-components';
import { PlotSerie as BasePlotSerie, PlotSerieProps } from '@elzeard/common-planning';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { colorStyle } from '../../../common/style/colors';
import { getTextStyle } from '../../../common/style/text';
import { usePositionApplyCommand, usePositionState } from '../state-context';
import { PositionSerie } from '../state-full';

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  .product-name {
    ${getTextStyle(12, 'medium')}
    color: ${colorStyle.greys['1-black']};
    margin: 0 0 0 4px !important;
  }
  .surface,
  .serie-number {
    ${getTextStyle(10, 'regular')}
    color: ${colorStyle.greys['1-black']};
    margin: 0 !important;
  }
`;

export function PlotSerie(props: PlotSerieProps) {
  const { formatNumber } = useTranslation();

  const { selectedParentProducts, plotSerieSelection, cultureSelection } = usePositionState();
  const applyCommand = usePositionApplyCommand();

  const serie = props.serie as PositionSerie;
  const parentProduct = selectedParentProducts[serie.parentCropItineraryId];
  const { endPosition, startPosition } = props;
  const {
    position: { position, serieId },
  } = props.position;

  const onClick: PlotSerieProps['onClick'] = useMemo(() => {
    const selection = plotSerieSelection || cultureSelection;
    const neededSurface = selection?.neededSurface || 0;

    const { cultureMode, parentCropItineraryId, serieIndex } = serie;
    const { plotId, bedId } = position;

    if (neededSurface < 0 && selection.serieId === serie.id) {
      return () => {
        applyCommand({
          type: 'decreaseSurface',
          parentCropItineraryId,
          cultureMode,
          serieIndex,
          serieId,
          plotId,
          bedId,
          startPosition,
          neededSurface,
          positionSurface: position.surface,
        });
      };
    } else {
      return () => {
        applyCommand({
          type: 'selectPlotSerie',
          parentCropItineraryId,
          cultureMode,
          serieIndex,
          serieId,
          plotId,
          bedId,
          startPosition,
          endPosition,
        });
      };
    }
  }, [applyCommand, cultureSelection, endPosition, plotSerieSelection, position, serie, serieId, startPosition]);

  return (
    <BasePlotSerie
      {...props}
      onClick={onClick}
    >
      <StyledContainer>
        <div className="product-name">{parentProduct.name}</div>
        <div className="surface">{formatNumber(position.surface, 0, 1)} m²</div>
        <div className="serie-number">
          {serie.serieIndex + 1} / {serie.numberOfSeries}
        </div>
      </StyledContainer>
    </BasePlotSerie>
  );
}
