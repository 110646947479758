import { getPlantColors, getWeek, MonthInterval, Week } from '@elzeard/common-components';
import { FarmingSystemEnum, rDateAsDate, SeriesStatus, Variable, YieldUnitEnum } from '@elzeard/shared-dimensions';
import {
  add,
  addDays,
  addWeeks,
  addYears,
  differenceInDays,
  differenceInWeeks,
  isBefore,
  isWithinInterval,
} from 'date-fns';
import { last } from 'lodash';
import { buildAvailabilityPeriod, buildStoragePeriod, ProductAvailability } from './common/ProductAvailability';
import { defaultLostRate } from './outlet/state-build';
import { BaseParentProduct, BaseProjectProduct, ChildProduct, ParentProduct, PepiniereSerie } from './state';
import { ParentItinerary, ReferenceItinerary } from './useCropItineraries';

export interface InitialBaseState {
  allPossibleParentProducts: Record<string, ParentProduct>;
  parentItineraryIdByChildId: Record<string, string>;
  parentItineraryIdByName: Record<string, string>;
}

export function buildAllPossibleParentProducts(
  parentItineraries: ParentItinerary[],
  time: MonthInterval,
  farmingSystem: FarmingSystemEnum,
): InitialBaseState {
  return parentItineraries.reduce(
    (acc, parentItinerary) => {
      if (parentItinerary.farmingSystem !== farmingSystem) {
        return acc;
      }
      const { children, ...baseParent } = prepareBaseParentProduct(parentItinerary);

      const { otherPossibleChildrenByChildItineraryId } = children.reduce(
        (acc, childItinerary) => {
          const childProduct = buildNewChildProduct(childItinerary, baseParent.parentCropItineraryId, time);
          acc.otherPossibleChildrenByChildItineraryId[childItinerary.id] = childProduct;
          acc.parentItineraryIdByChildId[childItinerary.id] = baseParent.parentCropItineraryId;
          return acc;
        },
        {
          otherPossibleChildrenByChildItineraryId: {} as Record<string, ChildProduct>,
          parentItineraryIdByChildId: acc.parentItineraryIdByChildId,
        },
      );
      const parent: ParentProduct = {
        ...baseParent,
        productId: null,
        productionNeedsByOutletRowId: {},
        removedNeeds: [],
        otherPossibleChildrenByChildItineraryId,
        selectedChildrenByRowId: {},
        purchaseResale: null,
      };
      acc.allPossibleParentProducts[parent.parentCropItineraryId] = parent;
      if (acc.parentItineraryIdByName[parent.name]) {
        // We need unique parent itinerary names in order to match the purchase-resale child products with their parent
        console.error(
          'The Pepiniere database is corrupted ! Two parent itineraries share the same name',
          acc.parentItineraryIdByName[parent.name],
          parent.parentCropItineraryId,
        );
      }
      acc.parentItineraryIdByName[parent.name] = parent.parentCropItineraryId;
      return acc;
    },
    {
      allPossibleParentProducts: {} as Record<string, ParentProduct>,
      parentItineraryIdByChildId: {} as Record<string, string>,
      parentItineraryIdByName: {} as Record<string, string>,
    },
  );
}

function prepareBaseProduct(cropItinerary: ReferenceItinerary, parentCropItineraryId: string): BaseProjectProduct {
  return {
    parentCropItineraryId,
    name: cropItinerary.name,
    plantFamily: cropItinerary.plantFamily,
    plantFamilyColors: getPlantColors(cropItinerary.plantFamilyColors),
    plantId: cropItinerary.plantId,
    plantName: cropItinerary.plantName,
    quantityUnit: cropItinerary.quantityUnit,
    itineraryYield: cropItinerary.yield,
    itkRotation: cropItinerary.itkRotation,
    rotation: cropItinerary.rotation,
    isUpdated: false,
  };
}

function prepareBaseParentProduct(cropItinerary: ParentItinerary): BaseParentProduct {
  const baseProduct = prepareBaseProduct(cropItinerary, cropItinerary.id);
  return {
    ...baseProduct,
    children: cropItinerary.children,
    prices: cropItinerary.prices,
  };
}

function buildNewChildProduct(
  childItinerary: ReferenceItinerary,
  parentCropItineraryId: string,
  time: MonthInterval,
): ChildProduct {
  const baseProduct = prepareBaseProduct(childItinerary, parentCropItineraryId);

  const timeEnd = addDays(last(time.weeks).firstDay, 6);
  const timeInterval = { start: time.weeks[0].firstDay, end: timeEnd };
  // Add 3 days to get the middle of the week: it increases the chances to keep the same week number.
  // TODO But it works only if it is an relative week => we would need to get the middle of the period
  const harvestBeginBaseDate = addDays(rDateAsDate(childItinerary.harvest.begin), 3);
  const direction = isBefore(harvestBeginBaseDate, timeEnd) ? 1 : -1;
  let harvestBeginDate = harvestBeginBaseDate;
  let yearsToAdd = 0;
  while (!isWithinInterval(harvestBeginDate, timeInterval) && Math.abs(yearsToAdd) < 1000) {
    yearsToAdd += direction;
    harvestBeginDate = addYears(harvestBeginDate, direction);
  }
  if (!isWithinInterval(harvestBeginDate, timeInterval)) {
    console.warn('Could not fit interval', { time, yearsToAdd, harvestBeginBaseDate });
    return null;
  }
  const harvestEndDate = addYears(addDays(rDateAsDate(childItinerary.harvest.end, true), 3), yearsToAdd);
  const implantationDate = addYears(addDays(rDateAsDate(childItinerary.implantationWeek), 3), yearsToAdd);
  const maturationWeeks = differenceInWeeks(harvestBeginDate, implantationDate);

  let harvestPeriods: ProductAvailability[];
  const isStartingBeforeProjectPeriod = implantationDate.getTime() < time.weeks[0].firstDay.getTime();
  if (isStartingBeforeProjectPeriod || harvestEndDate.getTime() > timeEnd.getTime()) {
    const harvestBegin = getWeek(harvestBeginDate);
    const harvestEnd = getWeek(harvestEndDate);
    if (isStartingBeforeProjectPeriod) {
      harvestPeriods = [
        buildAvailabilityPeriod(harvestBegin, harvestEnd),
        buildAvailabilityPeriod(getMatchingWeek(harvestBegin, true), getMatchingWeek(harvestEnd, true)),
      ];
    } else {
      harvestPeriods = [
        buildAvailabilityPeriod(getMatchingWeek(harvestBegin, false), getMatchingWeek(harvestEnd, false)),
        buildAvailabilityPeriod(harvestBegin, harvestEnd),
      ];
    }
  } else {
    harvestPeriods = [buildAvailabilityPeriod(getWeek(harvestBeginDate), getWeek(harvestEndDate))];
  }

  if (harvestPeriods.length === 0) {
    console.log(harvestBeginBaseDate, harvestBeginDate, harvestEndDate);
  }

  const series = harvestPeriods.map((period) =>
    buildNewSerie({
      harvestPeriod: period,
      maturationWeeks,
      storageWeeks: childItinerary.storageDurationWeeks,
      expectedYield: convertYield(childItinerary.yield),
      isUpdated: true,
    }),
  );
  return {
    ...baseProduct,
    // maturationWeeks,
    harvestPeriods,
    storagePeriods: harvestPeriods.map((harvestPeriod) =>
      buildStoragePeriod(harvestPeriod, childItinerary.storageDurationWeeks),
    ),
    rowId: childItinerary.id,
    childCropItineraryId: childItinerary.id,
    isUpdated: false,
    productId: null,
    cultureMode: childItinerary.cultureModes.length === 1 && childItinerary.cultureModes[0],
    series,
    serieToRemoveId: null,
    itinerarySeries: series,
  };
}

export function getMatchingWeek(week: Week, nextYear: boolean): Week {
  if (!week) {
    return null;
  }
  return getWeek(add(week.firstDay, { days: 3, years: nextYear ? 1 : -1 }));
}

function convertYield(originalYield: Variable<YieldUnitEnum>): number {
  const yieldUnit = originalYield?.unit;
  if (!yieldUnit) {
    return null;
  }
  const convertedYield = yieldUnit.includes('Hectare') ? (originalYield.value || 0) / 10000 : originalYield.value;
  return convertedYield;
}

export function buildNewSerie({
  harvestPeriod,
  maturationWeeks,
  storageWeeks,
  expectedYield,
  isUpdated,
}: {
  harvestPeriod: ProductAvailability;
  maturationWeeks: number;
  storageWeeks: number;
  expectedYield: number;
  isUpdated: boolean;
}): PepiniereSerie {
  const { weeks: harvestWeeks, ...harvest } = harvestPeriod;
  const serie: PepiniereSerie = {
    begin: getWeek(addWeeks(harvest.begin.firstDay, -maturationWeeks)),
    end: harvest.end,
    computedSurfaceNeeds: 0,
    editedSurfaceNeeds: null,
    expectedLostRate: defaultLostRate,
    expectedVolume: 0,
    expectedYield,
    harvest,
    harvestDays: differenceInDays(harvest.end.firstDay, harvest.begin.firstDay) + 6,
    harvestWeeks,
    isUpdated,
    id: Math.random().toString(),
    matureDays: maturationWeeks * 7,
    needs: {},
    positions: [],
    harvestPeriods: [
      {
        begin: harvest.begin,
        end: harvest.end,
        expectedYield: expectedYield,
        expectedVolume: 0,
        storageDays: storageWeeks * 7,
        computedSurfaceNeeds: 0,
      },
    ],
    storageDays: storageWeeks * 7,
    storageWeeks: {},
    isPerennial: false,
    serieId: null,
    bedWidth: null,
    footPassWidth: null,
    cultivarName: null,
    status: SeriesStatus.Planned,
  };
  return serie;
}
