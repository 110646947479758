/* eslint-disable import/no-anonymous-default-export */
export default {
  edit: {
    newProjectTitle: 'Paramètres du nouveau projet',
    'new-card-name': 'Nouveau projet',
    title: 'Paramètres de',
    form: {
      groups: {
        title: 'Titre du projet',
        address: 'Adresse géographique',
        coordinates: 'Coordonnées GPS (facultatif)',
        schedule: 'Début du calendrier de planification',
        outlet: 'Activer / désactiver un débouché',
      },
      fields: {
        projectTitle: {
          name: 'Titre du projet',
          error: {
            required: 'Le titre du projet est obligatoire',
          },
        },
        street1: {
          name: 'Adresse',
          error: {
            required: "L'adresse est obligatoire",
          },
        },
        street2: {
          name: "Complément d'adresse",
        },
        postalCode: {
          name: 'Code postal',
          error: {
            required: 'Le code postal est obligatoire',
            type: 'Le code postal doit être un nombre',
          },
        },
        city: {
          name: 'Ville',
          error: {
            required: 'La ville est obligatoire',
          },
        },
        longitude: {
          name: 'Longitude',
          error: {
            type: 'La longitude doit être un nombre',
          },
        },
        latitude: {
          name: 'Latitude',
          error: {
            type: 'La latitude doit être un nombre',
          },
        },
        // climate: {
        //   name: 'Nature du climat',
        //   error: {
        //     type: 'Veuillez précisez le climat',
        //   },
        // },
        // farmEquipmentLevel: {
        //   name: 'Niveau de mécanisation',
        //   error: {
        //     type: 'Veuillez précisez le niveau de mécanisation',
        //   },
        // },
        system: {
          name: "Type d'agriculture",
          error: {
            type: "Veuillez précisez le type d'agriculture",
          },
        },
        schedule: {
          name: 'Début du calendrier de planification',
        },
        outlet: {
          name: 'Activer / désactiver un débouché',
        },
      },
    },
  },
  card: {
    settings: 'Paramètres',
    duplicate: 'Dupliquer',
    notes: 'Notes',
    delete: 'Supprimer',
    create: 'Nouveau projet',
    'updated-at': 'Modifié le',
  },
  removal: {
    title: 'Suppression du projet',
    details: 'Etes-vous sûr de vouloir supprimer le projet {{project}} ? Toutes les données seront perdues.',
  },
  export: {
    planification: {
      culture: 'Culture',
      plantFamily: 'Famille botanique',
      cultureMode: 'Mode de culture',
      surface: 'Surface (m²)',
      harvestUnits: 'Unités',
      yield: 'Rendement',
      totalProductionFromOutlets: 'Production (ventes)',
      totalProductionFromSeries: 'Production (assolée)',
      totalSales: "Chiffre d'affaires (€)",
    },
    rotation: {
      culture: 'Culture',
      plantFamily: 'Famille botanique',
      implantationWeek: 'Implantation (semaine)',
      implantationYear: 'Implantation (année)',
      harvestBeginWeek: 'Début récolte (semaine)',
      harvestBeginYear: 'Début récolte (année)',
      endWeek: 'Fin de culture (semaine)',
      endYear: 'Fin de culture (année)',
      cultureMode: 'Mode de culture',
      harvestUnit: 'Unité',
      yield: 'Rendement (unité/m²)',
      surface: 'Surface (m²)',
      plot: 'Parcelle',
    },
    sales: {
      culture: 'Culture',
      outlet: 'Débouché',
    },
  },
};
