import {
  getMonth,
  getMonthInterval,
  getPlantFamilyColorsFromScientificName,
  MonthInterval,
} from '@elzeard/common-components';
import {
  BedPositions,
  computePlotWithBedsLanes,
  computePlotWithoutBedsLanes,
  PlotPositions,
  PlotWithBedsPositions,
  PlotWithoutBedsPositions,
  Rotation,
  SelectedPlot,
  SelectedPlotWithBeds,
  SelectedPlotWithoutBeds,
} from '@elzeard/common-planning';
import { CultureModeEnum, SeriesStatus, Without } from '@elzeard/shared-dimensions';
import { addMonths } from 'date-fns';
import { round, sortBy } from 'lodash';
import { BaseProjectPageState, ChildProduct, ParentProduct, ProjectPageState } from '../state';
import { Culture, PositionPageState, PositionSerie } from './state-full';

function buildCultureId(parentCropItineraryId: string, cultureMode: CultureModeEnum): string {
  return `${parentCropItineraryId}-${cultureMode}`;
}

export function convertToCulture(parentProduct: ParentProduct): Culture[] {
  const plantFamilyColorsName = getPlantFamilyColorsFromScientificName(parentProduct.plantFamily);
  const baseCulture = {
    itkRotation: parentProduct.itkRotation,
    rotation: parentProduct.rotation as Rotation[], // TODO publish seedsapi-types ?
    plantName: parentProduct.plantName,
    plantFamily: parentProduct.plantFamily,
    parentCropItineraryId: parentProduct.parentCropItineraryId,
    plantFamilyColorsName,
  };
  const childProductsByCultureMode: Record<CultureModeEnum, ChildProduct[]> = Object.values(
    parentProduct.selectedChildrenByRowId,
  ).reduce(
    (childProductsByCultureMode, childProduct) => {
      childProductsByCultureMode[childProduct.cultureMode].push(childProduct);
      return childProductsByCultureMode;
    },
    {
      // [CultureModeEnum.HeatedGreenHouse]: [],
      [CultureModeEnum.OpenField]: [],
      [CultureModeEnum.UnderCover]: [],
    },
  );

  const cultures = Object.entries(childProductsByCultureMode)
    .filter(([, children]) => children.length)
    .map(([cultureMode, children]: [CultureModeEnum, ChildProduct[]]) => {
      // TODO ensure compatibility
      const convertedSeries: PositionSerie[] = sortBy(
        children.flatMap((childProduct) => {
          return childProduct.series.map((baseSerie, childProductSerieIndex) => {
            const neededSurface = baseSerie.editedSurfaceNeeds || baseSerie.computedSurfaceNeeds;
            const remainingSurface = round(
              neededSurface - baseSerie.positions.reduce((total, position) => total + position.surface, 0),
              1,
            );
            const serie: Without<PositionSerie, 'serieIndex' | 'numberOfSeries'> = {
              ...baseSerie,
              childProductRowId: childProduct.rowId,
              cultureMode,
              parentCropItineraryId: parentProduct.parentCropItineraryId,
              remainingSurface,
              end: baseSerie.harvest.end,
              plantName: parentProduct.plantName,
              plantFamily: parentProduct.plantFamily,
              plantFamilyColorsName,
              neededSurface,
              childProductSerieIndex,
              cultivarName: null,
              status: baseSerie.positions.length ? SeriesStatus.Positioned : SeriesStatus.Planned,
            };
            return serie;
          });
        }),
        (serie) => serie.begin.firstDay.getTime(),
        (serie) => serie.matureDays,
        (serie) => serie.harvestDays,
        (serie) => serie.storageDays || 0,
      ).map((serie, serieIndex, cultureSeries) => ({
        ...serie,
        serieIndex,
        numberOfSeries: cultureSeries.length,
      }));
      const remainingSurfaceBySerie: [string, number][] = convertedSeries.map((serie) => [
        serie.id,
        serie.remainingSurface,
      ]);
      const remainingSeries = remainingSurfaceBySerie.filter(([, surface]) => surface !== 0);
      const neededSurface = round(
        convertedSeries.reduce(
          (total, serie) => total + (serie.editedSurfaceNeeds || serie.computedSurfaceNeeds || 0),
          0,
        ),
        1,
      );
      const culture: Culture = {
        ...baseCulture,
        id: buildCultureId(parentProduct.parentCropItineraryId, cultureMode),
        cultureMode: cultureMode,
        implantationMode: null, // TODO
        neededSurface,
        remainingSurfaceBySerie: Object.fromEntries(remainingSurfaceBySerie),
        remainingSeries: remainingSeries.length,
        remainingSurface: round(
          remainingSeries.reduce((total, [, remainingSurface]) => total + remainingSurface, 0),
          1,
        ),
        series: convertedSeries,
      };
      return culture;
    });
  return sortBy(
    cultures,
    ({ series }) => series[0].begin.firstDay.getTime(),
    ({ series }) => series[0].matureDays,
    ({ series }) => series[0].harvestDays,
    ({ series }) => series[0].storageDays || 0,
  );
}

export function enterPositionPage(
  baseState: BaseProjectPageState,
  previousState: ProjectPageState<PositionPageState>,
): PositionPageState {
  const cultures = Object.values(baseState.selectedParentProducts).flatMap((parentProduct) =>
    convertToCulture(parentProduct),
  );

  const seriesById = cultures.flatMap((culture) => culture.series.map((serie) => [serie.id, serie] as const));

  const { plotsById, bedsById } = baseState.basePlots.reduce(
    (acc, basePlot) => {
      if ('numberOfBeds' in basePlot) {
        const plot: PlotWithBedsPositions = {
          begin: null,
          end: null,
          cellId: null,
          cellName: null,
          ...basePlot,
          beds: basePlot.beds.map((bed) => ({
            ...bed,
            positions: [],
          })),
          precedingPlotsSeries: [],
        };
        acc.plotsById[plot.plotId] = plot;
        acc.bedsById = Object.assign(acc.bedsById, Object.fromEntries(plot.beds.map((bed) => [bed.bedId, bed])));
      } else {
        const plot: PlotWithoutBedsPositions = {
          begin: null,
          end: null,
          cellId: null,
          cellName: null,
          ...basePlot,
          positions: [],
          precedingPlotsSeries: [],
        };
        acc.plotsById[plot.plotId] = plot;
      }
      return acc;
    },
    {
      plotsById: {} as Record<string, PlotPositions>,
      bedsById: {} as Record<string, BedPositions>,
    },
  );

  for (const [, serie] of seriesById) {
    for (const position of serie.positions) {
      if (position.bedId) {
        bedsById[position.bedId].positions.push({
          serieId: serie.id,
          position,
        });
      } else {
        (plotsById[position.plotId] as PlotWithoutBedsPositions).positions.push({
          serieId: serie.id,
          position,
        });
      }
    }
  }

  const plots: SelectedPlot[] = Object.values(plotsById).map((plot) => {
    if ('beds' in plot) {
      const convertedPlot: SelectedPlotWithBeds = {
        plot,
        beds: plot.beds.map((bed) => ({
          bed,
          lanes: computePlotWithBedsLanes(bed.length, bed.positions),
        })),
      };
      return convertedPlot;
    } else {
      const convertedPlot: SelectedPlotWithoutBeds = {
        plot,
        lanes: computePlotWithoutBedsLanes(plot.surface, plot.positions),
      };
      return convertedPlot;
    }
  });

  const displayPeriod: MonthInterval =
    baseState.time.months.length === 12
      ? baseState.time
      : baseState.time.months.length > 12
      ? getMonthInterval(baseState.time.months[0], baseState.time.months[11])
      : (() => {
          const beginShift = Math.floor((12 - baseState.time.months.length) / 2);
          const beginDate = addMonths(baseState.time.months[0].begin, -beginShift);
          return getMonthInterval(getMonth(beginDate), getMonth(addMonths(beginDate, 11)));
        })();

  const plotDisplayPeriod = previousState?.plotDisplayPeriod || displayPeriod;
  const culturesDisplayPeriod = previousState?.culturesDisplayPeriod || displayPeriod;
  return {
    cultureSelection: null,
    plotSerieSelection: null,
    culturesDisplayPeriod,
    plotDisplayPeriod,
    seePositionedCultures: previousState?.seePositionedCultures || false,
    weekWidth: previousState?.weekWidth || 0,
    cultures,
    seriesById: Object.fromEntries(seriesById),
    plots,
    collapsedPlots: previousState?.collapsedPlots || {},
    isSurfaceIncludingWalkingSpace: null,
  };
}
export function leavePositionPage(state: ProjectPageState<PositionPageState>): BaseProjectPageState {
  const {
    cultureSelection,
    plotSerieSelection,
    // culturesDisplayPeriod,
    // plotDisplayPeriod,
    // seePositionedCultures,
    // collapsedPlots,
    // weekWidth,
    cultures,
    seriesById,
    plots,
    ...baseState
  } = state;
  return baseState;
}
export function updatePositionPageState(updatedState: ProjectPageState<PositionPageState>): PositionPageState {
  return {
    ...updatedState,
  };
}
