import { computeSelectedPlot, CulturesStateEditionAdapter, getSerieEnd, PlotPositions } from '@elzeard/common-planning';
import { last } from 'lodash';
import { ChildProduct, ParentProduct, PepiniereSerie, ProjectPageState } from '../state';
import { convertToCulture } from './state-build';
import { Culture, CultureCoordinates, PositionPageState, PositionSerie } from './state-full';

export function isCultureMatching(culture: Culture, coordinates: CultureCoordinates) {
  return (
    culture.parentCropItineraryId === coordinates.parentCropItineraryId &&
    culture.cultureMode === coordinates.cultureMode
  );
}

export const editionAdapter: CulturesStateEditionAdapter<
  CultureCoordinates,
  PositionSerie,
  PepiniereSerie,
  Culture,
  Pick<ProjectPageState<PositionPageState>, 'selectedParentProducts' | 'cultures' | 'seriesById'>,
  Pick<ProjectPageState<PositionPageState>, 'plots'>,
  ProjectPageState<PositionPageState>
> = {
  getAllPlotIds({ plots }) {
    return plots.map((plot) => plot.plot.plotId);
  },
  getCulture(cultures, coordinates) {
    return cultures.find((culture) => isCultureMatching(culture, coordinates));
  },
  getPlot(plotId, { plots }) {
    return plots.find((plot) => plot.plot.plotId === plotId)?.plot;
  },
  getProductCoordinates(selection) {
    return {
      parentCropItineraryId: selection.parentCropItineraryId,
      cultureMode: selection.cultureMode,
    };
  },
  getSerie(serieId, state) {
    return state.seriesById[serieId];
  },
  getSerieSurface(serie) {
    return serie.editedSurfaceNeeds || serie.computedSurfaceNeeds;
  },
  isCultureSelected: isCultureMatching,
  updateProductState(args) {
    const { action, productCoordinates, serieIndex, previousState: state } = args;
    const { parentCropItineraryId } = productCoordinates;
    const { selectedParentProducts, seriesById, cultures } = state;

    const cultureIndex = cultures.findIndex((culture) => isCultureMatching(culture, productCoordinates));
    const initialCulture = cultures[cultureIndex];
    const initialCultureSerie = initialCulture.series[serieIndex];

    const initialParentProduct = selectedParentProducts[parentCropItineraryId];
    const initialChildProduct = initialParentProduct.selectedChildrenByRowId[initialCultureSerie.childProductRowId];
    const initialSerie = initialChildProduct.series[initialCultureSerie.childProductSerieIndex];

    const { updatedSerie, ...rest } = action(initialSerie);
    const actionReturn = rest as ReturnType<typeof action>;

    if (updatedSerie !== initialSerie) {
      const updatedSeries = [...initialChildProduct.series];
      updatedSeries[initialCultureSerie.childProductSerieIndex] = updatedSerie;
      const updatedChildProduct: ChildProduct = {
        ...initialChildProduct,
        isUpdated: true,
        series: updatedSeries,
      };
      const updatedParentProduct: ParentProduct = {
        ...initialParentProduct,
        isUpdated: true,
        selectedChildrenByRowId: {
          ...initialParentProduct.selectedChildrenByRowId,
          [updatedChildProduct.rowId]: updatedChildProduct,
        },
      };

      const updatedCultures = [...cultures];
      const updatedCulture = convertToCulture(updatedParentProduct).find(
        (culture) => culture.cultureMode === updatedChildProduct.cultureMode,
      );
      updatedCultures[cultureIndex] = updatedCulture;

      return {
        updatedProductState: {
          selectedParentProducts: {
            ...selectedParentProducts,
            [parentCropItineraryId]: updatedParentProduct,
          },
          seriesById: {
            ...seriesById,
            ...Object.fromEntries(updatedCulture.series.map((serie) => [serie.id, serie])),
          },
          cultures: updatedCultures,
        },
        ...actionReturn,
      };
    } else {
      return {
        updatedProductState: state,
        ...actionReturn,
      };
    }
  },
  updateProductStateDraft({ action, productCoordinates, serieIndex, stateDraft }) {
    const { parentCropItineraryId } = productCoordinates;
    const { selectedParentProducts, seriesById, cultures } = stateDraft;

    const cultureIndex = cultures.findIndex((culture) => isCultureMatching(culture, productCoordinates));
    const cultureSerieDraft = cultures[cultureIndex].series[serieIndex];

    const parentProductDraft = selectedParentProducts[parentCropItineraryId];
    const childProductDraft = parentProductDraft.selectedChildrenByRowId[cultureSerieDraft.childProductRowId];
    const serieDraft = childProductDraft.series[cultureSerieDraft.childProductSerieIndex];

    const actionReturn = action(serieDraft) as ReturnType<typeof action>;

    if (!actionReturn.shouldNotProceed) {
      childProductDraft.isUpdated = true;
      parentProductDraft.isUpdated = true;

      const updatedCulture = convertToCulture(parentProductDraft).find(
        (culture) => culture.cultureMode === childProductDraft.cultureMode,
      );

      cultures[cultureIndex] = updatedCulture;

      for (const serie of updatedCulture.series) {
        seriesById[serie.id] = serie;
      }
    }

    return actionReturn;
  },
  updatePlotState,
  updatePlotStateDraft(editedPlots, stateDraft) {
    stateDraft.plots = updatePlotState(editedPlots, stateDraft).plots;
  },
};

function updatePlotState(editedPlots: PlotPositions[], state: ProjectPageState<PositionPageState>) {
  const editedPlotsById = editedPlots.reduce((acc, plot) => {
    acc[plot.plotId] = plot as PlotPositions;
    return acc;
  }, {} as Record<string, PlotPositions>);

  const begin = state.plotDisplayPeriod.weeks[0].firstDay;
  const end = getSerieEnd(last(state.plotDisplayPeriod.weeks), 0);

  return {
    plots: state.plots.map((plot) =>
      editedPlotsById[plot.plot.plotId]
        ? computeSelectedPlot(
            editedPlotsById[plot.plot.plotId],
            begin,
            end,
            (position) => state.seriesById[position.serieId],
          )
        : plot,
    ),
  };
}
