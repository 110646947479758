import { Timeline, useTranslation } from '@elzeard/common-components';
import { CultureBlockCommandProvider, CulturesColumn, RestorePositions } from '@elzeard/common-planning';
import React, { CSSProperties, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ConfirmationModal } from '../../common/components/modal/ConfirmationModal';
import { ProjectPageSpinner } from '../ProjectPage';
import { ExportProjectButton } from '../common/ExportProjectButton';
import { SaveProjectButton } from '../common/SaveProjectButton';
import { useCommonProjectCommands } from '../state-context';
import { PageContainer } from '../table/page-containers';
import { AllPositionPageCommands } from './commands';
import { CollapsiblePlot } from './components/CollapsiblePlot';
import { CreatePlotModal } from './plot-modal/CreatePlotModal';
import { cultureMonthWidth } from './state-adapter-cultures';
import { enterPositionPage, leavePositionPage, updatePositionPageState } from './state-build';
import { usePositionApplyCommand, usePositionState } from './state-context';
import { CultureCoordinates } from './state-full';
import { NavigationButton, NavigationContainer, PositionPageTopbar, StyledScenarioPositionTable } from './style';

export function PositionPage() {
  const { pathname: routePath } = useLocation();

  const applyCommand = useCommonProjectCommands();
  const state = usePositionState();

  // TODO find a way to initialize state without a useless first render
  useEffect(() => {
    applyCommand({
      type: 'enterPage',
      routePath,
      enter: enterPositionPage,
      update: updatePositionPageState,
    });
    return () => {
      applyCommand({
        type: 'leavePage',
        routePath,
        function: leavePositionPage,
      });
    };
  }, [applyCommand, routePath]);

  return state && 'plots' in state ? <PositionPageInner /> : <ProjectPageSpinner textI18nKey="outlet:home.spinner" />;
}

export function PositionPageInner() {
  const { t } = useTranslation();
  const applyCommand = usePositionApplyCommand();
  const { culturesDisplayPeriod, plotDisplayPeriod, plots, cultureSelection, plotSerieSelection } = usePositionState();

  const [mustShowCreatePlotModal, setMustShowCreatePlotModal] = useState(false);

  const cultureTimelineWidthStyle: CSSProperties = useMemo(() => {
    return {
      width: culturesDisplayPeriod.months.length * cultureMonthWidth + 'px',
    };
  }, [culturesDisplayPeriod.months.length]);

  const navigatePlotPeriod = useCallback(
    (offset: number) => {
      applyCommand({
        type: 'navigatePlotPeriod',
        direction: offset,
      });
    },
    [applyCommand],
  );

  const setWeekWidth = useCallback(
    (weekWidth) =>
      applyCommand({
        type: 'setWeekWidth',
        weekWidth,
      }),
    [applyCommand],
  );

  const [restorePositionCommand, setRestorePositionCommand] = useState<AllPositionPageCommands | false>(false);
  const closeConfirmRestorePositionModal = useCallback(() => {
    setRestorePositionCommand(false);
  }, []);
  const confirmRestorePositions = useCallback(() => {
    applyCommand(restorePositionCommand as RestorePositions<CultureCoordinates>);
    setRestorePositionCommand(false);
  }, [applyCommand, restorePositionCommand]);

  const isMoving = !!cultureSelection?.neededSurface || plotSerieSelection?.isMoving;

  return (
    <PageContainer>
      <PositionPageTopbar
        left={
          <>
            <NavigationContainer></NavigationContainer>
            <SaveProjectButton />
            <ExportProjectButton />
          </>
        }
        right={
          <>
            <NavigationButton
              label={t('position:topbar.create-plot')}
              onClick={() => setMustShowCreatePlotModal(true)}
            />
          </>
        }
      />
      <CultureBlockCommandProvider
        value={{
          editSerie: () => {},
          restorePositions: setRestorePositionCommand,
        }}
      >
        <StyledScenarioPositionTable>
          <CulturesColumn
            className="cultures-column"
            cultureTimelineWidthStyle={cultureTimelineWidthStyle}
          />
          <div className="plots-column">
            <Timeline
              className="plots-timeline"
              weekWidth={{ onWeekWidthChange: setWeekWidth }}
              weekMargin={0}
              time={plotDisplayPeriod}
              navigable={{
                navigateTime: navigatePlotPeriod,
              }}
            />
            <div className="plots-container">
              {plots.map((plot) => (
                <CollapsiblePlot
                  key={plot.plot.plotId}
                  isMoving={isMoving}
                  plot={plot}
                />
              ))}
            </div>
          </div>
        </StyledScenarioPositionTable>
      </CultureBlockCommandProvider>
      {restorePositionCommand && (
        <ConfirmationModal
          title={t('common-planning:modal.restore-positions.content')}
          onConfirm={confirmRestorePositions}
          onCancel={closeConfirmRestorePositionModal}
        />
      )}
      {mustShowCreatePlotModal && <CreatePlotModal onClose={() => setMustShowCreatePlotModal(false)} />}
    </PageContainer>
  );
}
