import { CulturesAdapter, RestorePositions, SelectPlotSerie } from '@elzeard/common-planning';
import { arePeriodsOverlapping, Period } from '@elzeard/shared-dimensions';
import React from 'react';
import { Checkbox } from '../../common/components/field/Checkbox';
import { ProjectPageState } from '../state';
import { PositionPageCommands } from './commands';
import { CultureBlockTitle } from './components/CultureBlockTitle';
import { isCultureMatching } from './edition-adapter';
import { Culture, CultureCoordinates, PositionPageState, PositionSerie } from './state-full';

export const cultureMonthWidth = 30;

export const planningCulturesStateAdapter: CulturesAdapter<
  CultureCoordinates,
  PositionSerie,
  PositionSerie,
  Culture,
  ProjectPageState<PositionPageState>,
  PositionPageCommands
> = {
  cultureMonthWidth,
  getPlanningPeriod(state) {
    return state.time;
  },
  isPlanningDisabled(state) {
    return false;
  },
  isCultureSelected(culture, cultureSelection) {
    return cultureSelection && isCultureMatching(culture, cultureSelection);
  },
  getSerieSurface(serie) {
    return serie.editedSurfaceNeeds || serie.computedSurfaceNeeds;
  },
  isCultureSerieSelectable(serie, state) {
    const displayPeriod: Period = {
      start: state.culturesDisplayPeriod.begin,
      end: state.culturesDisplayPeriod.end,
    };
    return (
      serie.neededSurface &&
      arePeriodsOverlapping({ start: serie.begin.firstDay, end: serie.end?.firstDay }, displayPeriod)
    );
  },
  isCultureSerieSelected(serie, culture, selection) {
    return (
      selection &&
      selection.parentCropItineraryId === (serie as PositionSerie).parentCropItineraryId &&
      selection.cultureMode === (serie as PositionSerie).cultureMode &&
      (!('serieIndex' in selection) || selection.serieIndex === (serie as PositionSerie).serieIndex)
    );
  },
  CulturesColumn: {
    CheckboxComponent({ label, onValueChange, value, disabled }) {
      return (
        <Checkbox
          label={label}
          onValueChange={onValueChange}
          value={value}
          disabled={disabled}
        />
      );
    },
  },
  CultureBlock: {
    TitleComponent: CultureBlockTitle,
    getRestorePositionCommand(cultureSelection) {
      const { parentCropItineraryId, cultureMode, serieIndex, serieId } = cultureSelection;
      const command: RestorePositions<CultureCoordinates> = {
        type: 'restorePositions',
        parentCropItineraryId,
        cultureMode,
        serieIndex,
        serieId,
      };
      return command;
    },
    selectPlotSerie({ applyCommand, cultureSelection, plotId }) {
      const command: SelectPlotSerie<CultureCoordinates> = {
        type: 'selectPlotSerie',
        parentCropItineraryId: cultureSelection.parentCropItineraryId,
        cultureMode: cultureSelection.cultureMode,
        serieIndex: cultureSelection.serieIndex,
        serieId: cultureSelection.serieId,
        plotId: plotId,
      };
      applyCommand(command);
    },
    showEditButton: false,
  },
  CultureSerie: {
    toggleSelectPositionSerie({ applyCommand, culture, serieIndex, serieId }) {
      const { parentCropItineraryId, cultureMode } = culture;
      applyCommand?.({
        type: 'toggleSelectPositionSerie',
        parentCropItineraryId,
        cultureMode,
        serieIndex,
        serieId: serieId,
      });
    },
  },
};
