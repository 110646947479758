import {
  getPlantColors,
  Icon,
  IconButton,
  PlantFamilyColorsName,
  Tooltip,
  useTranslation,
} from '@elzeard/common-components';
import { PlanningPosition, PlotPositions, useCultureBlockCommands } from '@elzeard/common-planning';
import { addDays, format } from 'date-fns';
import { round } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { usePositionApplyCommand, usePositionState } from '../state-context';
import { PositionSerie } from '../state-full';

export interface PlotSerieSelectionContentProps {
  serie: PositionSerie;
  selectedPlot: PlotPositions;
  disabled?: boolean;
}

export const StyledPlotInfo = styled.div<{
  $selected: boolean;
  $plantFamilyColorsName: PlantFamilyColorsName;
}>`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-width: 1px;
  border-style: solid;
  border-radius: 20px;
  padding: 1px 8px;

  & > div:last-child {
    margin-left: 6px;
  }

  border-color: ${({ $plantFamilyColorsName }) => getPlantColors($plantFamilyColorsName).primary};

  ${({ $selected }) => $selected && 'border-style: dashed;'}
`;

export function PlotSerieSelectionContent({ serie, selectedPlot, disabled }: PlotSerieSelectionContentProps) {
  const { t } = useTranslation();
  const { plotSerieSelection } = usePositionState();
  const applyCommand = usePositionApplyCommand();

  const { restorePositions: setRestorePositionCommand } = useCultureBlockCommands();

  const selectedPlotId = selectedPlot.plotId;

  const positionsByPlot = Object.values(
    serie.positions.reduce((acc, position) => {
      const positionPlotId = position.plotId;
      let plotPositions = acc[positionPlotId];
      if (!plotPositions) {
        plotPositions = [];
        acc[positionPlotId] = plotPositions;
      }
      plotPositions.push(position);
      return acc;
    }, {} as Record<string, PlanningPosition[]>),
  );

  const restorePlotSelection = () => {
    const { parentCropItineraryId, cultureMode, serieIndex, serieId, plotId, bedId } = plotSerieSelection;

    setRestorePositionCommand({
      type: 'restorePositions',
      parentCropItineraryId,
      cultureMode,
      serieIndex,
      serieId,
      plotId,
      bedId, //, laneIndex
    });
  };
  // const resizePlotSelection = useCallback(() => {
  //   const { parentCropItineraryId, childProductRowId, serieIndex, serieId, begin, end, plotId, bedId } = plotSerieSelection;
  //   // applyCommand({
  //   //     type: '',
  //   //     parentCropItineraryId, childProductRowId, serieIndex, begin, end, plotId, bedId, laneIndex
  //   // })
  // }, [applyCommand, plotSerieSelection]);

  const toggleMovePlotSelection = () =>
    applyCommand({
      type: 'toggleMovePlotSerieSelection',
    });

  const selectSerie = (plotId: string) => {
    applyCommand({
      type: 'selectPlotSerie',
      parentCropItineraryId: serie.parentCropItineraryId,
      cultureMode: serie.cultureMode,
      serieIndex: serie.serieIndex,
      serieId: serie.id,
      plotId,
    });
  };

  return (
    <>
      <div className="serie-actions">
        <Tooltip
          content={t('common-planning:plot.topBar.tools.shift')}
          offset={[0, 16]}
          disabled={
            disabled ||
            'lanes' in selectedPlot ||
            !plotSerieSelection ||
            !('startPosition' in plotSerieSelection) ||
            !plotSerieSelection.bedId
          }
        >
          <IconButton
            value="shift"
            disabled={
              disabled ||
              'lanes' in selectedPlot ||
              !plotSerieSelection ||
              !('startPosition' in plotSerieSelection) ||
              !plotSerieSelection.bedId
            }
            onClick={toggleMovePlotSelection}
          />
        </Tooltip>
        {/* <Tooltip
              content={t('common-planning:plot.topBar.tools.resize')}
              offset={[0, 16]}
              disabled={true}
            >
              <IconButton
                // TODO
                value="resize"
                disabled={true}
                onClick={resizePlotSelection}
              />
            </Tooltip> */}
        <Tooltip
          content={t('common-planning:plot.topBar.tools.restore')}
          offset={[0, 16]}
          disabled={disabled}
        >
          <IconButton
            value="restore"
            variant="error"
            disabled={disabled}
            onClick={restorePlotSelection}
          />
        </Tooltip>
      </div>
      <div className="serie-info">
        <div className="title-line">
          <div className="plant-name">{serie.plantName}</div>
          {/* <div className="cultivar-name">{serie.cultivarName}</div> */}
          <div className="line"></div>
          <div className="serie-number">{`${serie.serieIndex + 1} / ${serie.numberOfSeries}`}</div>
        </div>
        <div className="date-line">
          <div className="dates">
            <Icon iconName="agenda" />
            {`${format(serie.begin.firstDay, 'dd/MM')} - ${format(addDays(serie.harvest.end.firstDay, 6), 'dd/MM')}`}
          </div>
          <div className="surface">
            <Icon iconName="resize" />
            {`${round(serie.neededSurface - serie.remainingSurface, 1)} / ${serie.neededSurface} m²`}
          </div>
        </div>
        <div className="positions">
          {positionsByPlot.map((plotPositions) => {
            const { plotId, plotName } = plotPositions[0];
            const { beds, surface } = plotPositions.reduce(
              ({ beds, surface }, position) => {
                return {
                  beds: position.bedId
                    ? {
                        ...beds,
                        [position.bedId]: position.bedId,
                      }
                    : beds,
                  surface: round(surface + position.surface, 1),
                };
              },
              { beds: {} as Record<string, string>, surface: 0 },
            );
            const numberOfBeds = Object.keys(beds).length;
            return (
              <div
                key={plotId}
                className="plot-info-line"
              >
                <StyledPlotInfo
                  className="plot-info"
                  $selected={selectedPlotId === plotId}
                  $plantFamilyColorsName={serie.plantFamilyColorsName}
                >
                  <div>
                    {plotName}
                    {numberOfBeds ? ` - ${numberOfBeds} ${t('common-planning:cultures.beds')}` : ''}
                  </div>
                  <div>{`${surface} m²`}</div>
                </StyledPlotInfo>
                <Tooltip
                  content={t('common-planning:plot.topBar.tools.resize')}
                  disabled={selectedPlotId === plotId}
                >
                  <IconButton
                    value="eye"
                    sizing="S" //{16}
                    variant="primary"
                    disabled={selectedPlotId === plotId}
                    onClick={() => selectSerie(plotId)}
                  />
                </Tooltip>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}
