import { useTranslation } from '@elzeard/common-components';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { routes } from '../../app/routes';
import ImgLogo from '../../assets/colored-logo-158.svg';
import ButtonObsolete from '../../common/components/ButtonObsolete';
import { ErrorMessage } from '../../common/components/ErrorMessage';
import InputField from '../../common/components/field/InputField';
import { useAuth } from '../../common/context/AuthContext';
import { useSearchParams } from '../../common/hook/useSearchParams';
import { colorStyle } from '../../common/style/colors';
import { getTextStyle, textStyle } from '../../common/style/text';
import config from '../../config';

const Container = styled.div`
  display: flex;
  gap: 16px;
`;
const Logo = styled.img`
  height: 100%;
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Actions = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 8px;
`;

const TitleBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const Title = styled.span`
  ${textStyle['title']}
`;
const TutorielLink = styled.a`
  margin-left: auto;
  font-weight: 600;
  text-decoration: underline;
`;

const ConfirmationMessage = styled.div`
  padding: 10px 16px;
  background-color: ${colorStyle.validate['3']};
  color: ${colorStyle.greys['1-black']};
  ${getTextStyle(12, 'regular')}
`;

interface IFormValues {
  username: string;
  password: string;
}

export function ViewLogin() {
  const { t } = useTranslation();
  const [apiError, setApiError] = useState<string | null>();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<IFormValues>();

  const location = useLocation();
  const history = useHistory();
  const auth = useAuth();
  const urlQuery = useSearchParams();

  const isRegisterComplete = useMemo(() => urlQuery.get('registerComplete') === '1', [urlQuery]);

  // @ts-ignore
  const from = location.state?.from?.pathname || '/';

  const onSubmit = async (formData: IFormValues) => {
    setApiError(null);
    try {
      await auth.signin(formData.username, formData.password);
    } catch (e) {
      setApiError(
        e.graphQLErrors?.[0]?.message === 'Failed to signin'
          ? t('common:error.wrong-password')
          : t('common:error.generic'),
      );
    }
  };

  /**
   * Remove the API error message after the user has changed any of the input fields
   */
  const username = watch('username');
  const password = watch('password');
  useEffect(() => {
    setApiError(null);
  }, [username, password]);

  useEffect(() => {
    localStorage.removeItem(config.AUTH_TOKEN);
  }, []);

  if (auth.user?.id) {
    return <Redirect to={from} />;
  }
  return (
    <Container>
      <Logo src={ImgLogo} />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <TitleBar>
          <Title>{t('common:app.title')}</Title>
          <TutorielLink
            href="https://edued.fr/BAS/pepiniere/"
            target="_blank"
            rel="noreferrer"
          >
            {t('common:app.tutorial')}
          </TutorielLink>
        </TitleBar>
        <InputField
          placeholder={t('common:fields.email')}
          fieldName="username"
          register={register}
          errors={errors}
          required
          type="email"
        />

        <InputField
          placeholder={t('common:fields.password')}
          type="password"
          fieldName="password"
          register={register}
          errors={errors}
          required
        />
        <ErrorMessage message={(errors.password || errors.username) && t('common:error.empty-field')} />
        <ErrorMessage message={apiError} />
        {isRegisterComplete && <ConfirmationMessage>{t('common:auth.login.register-complete')}</ConfirmationMessage>}
        <Actions>
          <ButtonObsolete
            variant="contained"
            type="submit"
          >
            {t('common:auth.login.button-login')}
          </ButtonObsolete>
          <ButtonObsolete
            variant="contained"
            onClick={() => history.push(routes.login.forgotPassword)}
          >
            {t('common:auth.login.button-reset-password')}
          </ButtonObsolete>
          <ButtonObsolete
            variant="contained"
            onClick={() => history.push(routes.login.createAccount)}
          >
            {t('common:auth.login.button-create-account')}
          </ButtonObsolete>
        </Actions>
      </Form>
    </Container>
  );
}
