import { useTranslation } from '@elzeard/common-components';
import { CultureModeEnum } from '@elzeard/shared-dimensions';
import { yupResolver } from '@hookform/resolvers/yup';
import { identity } from 'lodash';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components';
import * as yup from 'yup';
import { Field } from '../../../common/components/field/Field';
import { HookedTextInput } from '../../../common/components/field/InputField';
import { Radio } from '../../../common/components/field/Radio';
import { HookedRadioGroup } from '../../../common/components/field/RadioGroup';
import { CenteredModal } from '../../../common/components/modal/CenteredModal';
import { ModalErrorInformation } from '../../../common/components/modal/RightFormModal';
import { colorStyle } from '../../../common/style/colors';
import { getTextStyle } from '../../../common/style/text';
import { translateCultureModeEnum } from '../../../shared-elzeard/constants/translate-constant';
import { PlotForm } from './CreatePlotModal';

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: flex-start; */
  justify-content: center;
  padding: 24px;
  gap: 16px;
  /* width: 250px; */
  background: ${colorStyle.cta2['6']};
  border-width: 2px 0px;
  border-style: solid;
  border-color: ${colorStyle.cta2['1']};

  & > .field {
    & > label:first-child {
      ${getTextStyle(12, 'regular')}
      text-transform: uppercase;
    }
  }
`;

const cultureModes = [CultureModeEnum.OpenField, CultureModeEnum.UnderCover /*, CultureModeEnum.HeatedGreenHouse*/];

export function PlotInfoModal({
  loading,
  value,
  onConfirm,
  onClose,
  onPrevious,
}: {
  loading: boolean;
  value: PlotForm;
  onClose: () => void;
  onPrevious: () => void;
  onConfirm: (form: PlotForm) => void;
}) {
  const { t } = useTranslation();
  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitted, isValid },
    setValue,
    getValues,
  } = useForm<PlotForm>({
    resolver: yupResolver(
      yup.object({
        name: yup.string().required(),
        cultureMode: yup.string().required(),
        withBeds: yup.boolean().required(),
        length: yup.number().required(),
        width: yup.number().required(),
      }),
    ),
    defaultValues: value,
  });

  return (
    <CenteredModal
      title={t('position:plot-modal.info-step.create.title')}
      mainActionLabelKey={getValues().withBeds ? 'common:button.next' : 'common:button.validate'}
      cancelActionLabelKey="common:button.previous"
      footerInfo={
        isSubmitted && !isValid && <ModalErrorInformation>{t('common:error.missing-fields')}</ModalErrorInformation>
      }
      noCloseButton
      disabled={!value}
      loading={loading}
      onConfirm={handleSubmit(onConfirm)}
      onCancel={onPrevious}
      onClose={onClose}
    >
      <StyledContent>
        <HookedTextInput
          label={t('position:plot-modal.info-step.name')}
          required
          placeholder={t('position:plot-modal.info-step.name')}
          fieldName="name"
          register={register}
          errors={errors}
        />
        <HookedRadioGroup
          fieldName="cultureMode"
          label={t('position:plot-modal.info-step.culture-mode')}
          required
          options={cultureModes}
          getOptionLabel={(value) => translateCultureModeEnum(t, value)}
          getOptionId={identity}
          control={control}
        />
        <Field $error={!!errors.withBeds}>
          <label>{t('position:plot-modal.info-step.with-beds.label')} *</label>
          <Controller
            control={control}
            name="withBeds"
            render={({ field, fieldState }) => (
              <>
                <Radio
                  className="checkbox"
                  value={field.value}
                  onValueChange={(checked) => checked && setValue('withBeds', true)}
                  label={t('position:plot-modal.info-step.with-beds.yes')}
                  variant="border"
                  error={fieldState.error?.message}
                />
                <Radio
                  className="checkbox"
                  value={field.value === false}
                  onValueChange={(checked) => checked && setValue('withBeds', false)}
                  label={t('position:plot-modal.info-step.with-beds.no')}
                  variant="border"
                  error={fieldState.error?.message}
                />
              </>
            )}
          />
        </Field>
      </StyledContent>
    </CenteredModal>
  );
}
