import { TFunction, useTranslation } from '@elzeard/common-components';
import { CultureModeEnum, HarvestUnitEnum } from '@elzeard/shared-dimensions';
import React, { ComponentType, Dispatch, Fragment, ReactNode } from 'react';
import styled from 'styled-components';
import { Svg } from '../../../common/components/Svg';
import { getTextStyle } from '../../../common/style/text';
import { suffixNumber } from '../../../common/utils/math';
import {
  translateCultureModeEnum,
  translateHarvestUnitEnum,
  translateYieldUnitEnum,
} from '../../../shared-elzeard/constants/translate-constant';
import { cultureModeIcons } from '../../common/icons';
import { CultureMode, ParentProduct } from '../../state';
import {
  HeaderEmptyRow,
  HeaderTable,
  HeaderTableBody,
  HeaderTableContainer,
  HeaderTableFoot,
  HeaderTableHead,
} from '../../table/header-table-containers';
import { MarginRightCell, TopHeaderCell, TopHeaderTitle, TopHeaderUnit } from '../../table/style';
import { AllSeriesCommands } from '../commands';
import { useSeriesState } from '../state-context';
import { SeriesPageStateDisplayOptions } from '../state-displayOptions';
import { ProductSerie, SeriesProduct } from '../state-full';
import { SeriesHeaderRows } from './SeriesHeaderRows';
import { TitleCell } from './SeriesHeaderRows-style';

export interface IntegerField {
  type: 'integer';
}
export interface PercentField {
  type: 'percent';
}
export interface WeekField {
  type: 'week';
}
export interface IconEnumField {
  type: 'icon';
  options: string[];
  optionIcons: Record<string, ComponentType>;
  getText(t: TFunction, value: string): string;
}
export interface TextEnumField {
  type: 'text';
  options?: string[];
  getText(t: TFunction, value: string): string;
}

export type HeaderFieldDef = IntegerField | PercentField | WeekField | IconEnumField | TextEnumField;
export type HeaderFieldType = HeaderFieldDef['type'];
export interface HeaderCellProps {
  serie: ProductSerie;
  fieldDefinition: HeaderFieldDef;
  propertyName: SerieHeaderColumnName;
  isEditing: boolean;
  applyCommand: Dispatch<AllSeriesCommands>;
}

export type SerieHeaderColumnName = keyof Pick<
  ProductSerie,
  | 'cultureMode'
  | 'implantationWeek'
  | 'maturationDuration'
  | 'harvestDuration'
  | 'storageDuration'
  | 'harvestQuantityUnit'
  | 'expectedYield'
  | 'lossMargin'
  | 'surface'
  | 'bedLength'
  | 'quantity'
>;

export type ProductHeaderColumnName = keyof Pick<SeriesProduct, 'cultureMode' | 'quantity' | 'harvestQuantityUnit'>;

export const noSelectionColumns: Array<ProductHeaderColumnName> = ['cultureMode', 'quantity', 'harvestQuantityUnit'];
const selectedProductColumns: Record<
  SeriesPageStateDisplayOptions['displayedColumns'],
  Array<SerieHeaderColumnName>
> = {
  duration: ['cultureMode', 'implantationWeek', 'maturationDuration', 'harvestDuration', 'storageDuration', 'quantity'],
  quantity: ['cultureMode', 'expectedYield', 'lossMargin', 'surface', 'quantity'], // 'bedLength',
};

export interface HeaderProps {
  t: TFunction;
  product?: SeriesProduct;
  parentProduct?: ParentProduct;
}
export interface SerieValueCellProps<C extends SerieHeaderColumnName> {
  value: ProductSerie[C];
  t: TFunction;
  formatNumber: ReturnType<typeof useTranslation>['formatNumber'];
  serieOrProduct?: ProductSerie | SeriesProduct;
}
export type SeriesColumnDefinition<C extends SerieHeaderColumnName> = HeaderFieldDef & {
  isEditable: boolean;
  header(props: HeaderProps): ReactNode;
  value(props: SerieValueCellProps<C>): ReactNode;
};
export type ProductColumnDefinition<C extends ProductHeaderColumnName> = HeaderFieldDef & {
  isEditable: boolean;
  header(props: HeaderProps): ReactNode;
  value(props: SerieValueCellProps<C>): ReactNode;
};

const HeaderCell = styled(TopHeaderCell)`
  .content {
    display: flex;
    flex-direction: column;
    height: calc(100% - 13px); // 13px = cell bottom padding
  }
`;

const EditedSurface = styled.div`
  ${getTextStyle(14, 'semibold')}
`;

export function displayValue({
  formatNumber,
  t,
  value,
}: {
  value: number;
  t: TFunction;
  formatNumber: ReturnType<typeof useTranslation>['formatNumber'];
}) {
  return suffixNumber(value, formatNumber) ?? '-';
}
export const productsColumnDefinitions: {
  [C in ProductHeaderColumnName]: ProductColumnDefinition<C>;
} = {
  cultureMode: {
    type: 'icon',
    isEditable: true,
    optionIcons: cultureModeIcons,
    options: [
      CultureModeEnum.OpenField,
      CultureModeEnum.UnderCover,
      // CultureModeEnum.HeatedGreenHouse,
      // 'PurchaseResale',
    ],
    getText: (t, value: CultureMode) =>
      !value
        ? null
        : value === 'Mixed'
        ? t('series:header-table.values.culture-mode.Mixed')
        : value === 'PurchaseResale'
        ? t('series:header-table.values.culture-mode.PurchaseResale')
        : translateCultureModeEnum(t, value),
    header({ t }) {
      return (
        <TopHeaderCell
          key="cultureMode"
          title={t('series:header-table.no-selection.head.cultureMode.tooltip')}
        >
          <TopHeaderTitle>{t('series:header-table.no-selection.head.cultureMode.label')}</TopHeaderTitle>
        </TopHeaderCell>
      );
    },
    value({ value, t }) {
      return (
        <Svg
          value={value ? cultureModeIcons[value] : cultureModeIcons.Mixed}
          size={20}
        />
      );
    },
  },
  harvestQuantityUnit: {
    type: 'text',
    isEditable: false,
    getText: (t, value) =>
      translateHarvestUnitEnum(
        t,
        value as HarvestUnitEnum,
        value === HarvestUnitEnum.Kilogram || value === HarvestUnitEnum.Tonne ? 'short' : 'long',
      ),
    header({ t }) {
      return (
        <TopHeaderCell
          key="harvestQuantityUnit"
          title={t('series:header-table.no-selection.head.harvestQuantityUnit.tooltip')}
        >
          <TopHeaderTitle>{t('series:header-table.no-selection.head.harvestQuantityUnit.label')}</TopHeaderTitle>
        </TopHeaderCell>
      );
    },
    value({ value, t }) {
      return (
        translateHarvestUnitEnum(
          t,
          value,
          value === HarvestUnitEnum.Kilogram || value === HarvestUnitEnum.Tonne ? 'short' : 'long',
        ) || '-'
      );
    },
  },
  quantity: {
    type: 'integer',
    isEditable: false,
    header({ t }) {
      return (
        <TopHeaderCell
          key="quantity"
          title={t('series:header-table.no-selection.head.quantity.tooltip')}
        >
          <TopHeaderTitle>{t('series:header-table.no-selection.head.quantity.label')}</TopHeaderTitle>
        </TopHeaderCell>
      );
    },
    value(args) {
      const formattedValue = displayValue(args);
      return args.serieOrProduct?.computedQuantity != null
        ? `${formattedValue} / ${displayValue({
            ...args,
            value: args.serieOrProduct.computedQuantity,
          })}`
        : formattedValue;
    },
  },
};

export const seriesColumnDefinitions: {
  [C in SerieHeaderColumnName]: SeriesColumnDefinition<C>;
} = {
  cultureMode: productsColumnDefinitions.cultureMode,
  implantationWeek: {
    type: 'week',
    isEditable: true,
    header({ t }) {
      return (
        <HeaderCell
          key="implantationWeek"
          title={t('series:header-table.product-selected.head.implantationWeek.tooltip')}
        >
          <div className="content">
            <TopHeaderTitle>{t('series:header-table.product-selected.head.implantationWeek.label')}</TopHeaderTitle>
            <TopHeaderUnit key="implantationWeek">
              {t('series:header-table.product-selected.head.implantationWeek.unit')}
            </TopHeaderUnit>
          </div>
        </HeaderCell>
      );
    },
    value({ value, t }) {
      return value?.weekNumber || '-';
    },
  },
  maturationDuration: {
    type: 'integer',
    isEditable: true,
    header({ t }) {
      return (
        <HeaderCell
          key="maturationDuration"
          title={t('series:header-table.product-selected.head.maturationWeeks.tooltip')}
        >
          <div className="content">
            <TopHeaderTitle>{t('series:header-table.product-selected.head.maturationWeeks.label')}</TopHeaderTitle>
            <TopHeaderUnit key="maturationDuration">
              {t('series:header-table.product-selected.head.maturationWeeks.unit')}
            </TopHeaderUnit>
          </div>
        </HeaderCell>
      );
    },
    value({ value, t }) {
      return value || '-';
    },
  },
  harvestDuration: {
    type: 'integer',
    isEditable: true,
    header({ t }) {
      return (
        <HeaderCell
          key="harvestDuration"
          title={t('series:header-table.product-selected.head.harvestWeeks.tooltip')}
        >
          <div className="content">
            <TopHeaderTitle>{t('series:header-table.product-selected.head.harvestWeeks.label')}</TopHeaderTitle>
            <TopHeaderUnit key="harvestDuration">
              {t('series:header-table.product-selected.head.harvestWeeks.unit')}
            </TopHeaderUnit>
          </div>
        </HeaderCell>
      );
    },
    value({ value, t }) {
      return value || '-';
    },
  },
  storageDuration: {
    type: 'integer',
    isEditable: true,
    header({ t }) {
      return (
        <HeaderCell
          key="storageDuration"
          title={t('series:header-table.product-selected.head.storageWeeks.tooltip')}
        >
          <div className="content">
            <TopHeaderTitle>{t('series:header-table.product-selected.head.storageWeeks.label')}</TopHeaderTitle>
            <TopHeaderUnit key="storageDuration">
              {t('series:header-table.product-selected.head.storageWeeks.unit')}
            </TopHeaderUnit>
          </div>
        </HeaderCell>
      );
    },
    value({ value, t }) {
      return value || '-';
    },
  },
  expectedYield: {
    type: 'integer', // TODO float
    isEditable: true,
    header({ t, parentProduct }) {
      return (
        <HeaderCell
          key="expectedYield"
          title={t('series:header-table.product-selected.head.expectedYield.tooltip')}
        >
          <div className="content">
            <TopHeaderTitle>{t('series:header-table.product-selected.head.expectedYield.label')}</TopHeaderTitle>
            <TopHeaderUnit key="expectedYield">
              {translateYieldUnitEnum(t, parentProduct.itineraryYield.unit)}
            </TopHeaderUnit>
          </div>
        </HeaderCell>
      );
    },
    value({ value, formatNumber }) {
      return value ? formatNumber(value, 0, 2) : '-';
    },
  },
  lossMargin: {
    type: 'percent',
    isEditable: true,
    header({ t }) {
      return (
        <HeaderCell
          key="lossMargin"
          title={t('series:header-table.product-selected.head.lossMargin.tooltip')}
        >
          <div className="content">
            <TopHeaderTitle>{t('series:header-table.product-selected.head.lossMargin.label')}</TopHeaderTitle>
            <TopHeaderUnit key="lossMargin">
              {t('series:header-table.product-selected.head.lossMargin.unit')}
            </TopHeaderUnit>
          </div>
        </HeaderCell>
      );
    },
    value({ value, t }) {
      return value ? value * 100 : '-';
    },
  },
  surface: {
    type: 'integer',
    isEditable: true,
    header({ t }) {
      return (
        <HeaderCell
          key="surface"
          title={t('series:header-table.product-selected.head.surface.tooltip')}
        >
          <div className="content">
            <TopHeaderTitle>{t('series:header-table.product-selected.head.surface.label')}</TopHeaderTitle>
            <TopHeaderUnit key="surface">{t('series:header-table.product-selected.head.surface.unit')}</TopHeaderUnit>
          </div>
        </HeaderCell>
      );
    },
    value({ value, formatNumber, serieOrProduct: serie }) {
      const formattedValue = value ? formatNumber(value, 0, 0) : '-';
      return (serie as ProductSerie).editedSurface != null ? (
        <EditedSurface>{formattedValue}</EditedSurface>
      ) : (
        formattedValue
      );
    },
  },
  bedLength: {
    type: 'integer',
    isEditable: true,
    header({ t }) {
      return (
        <HeaderCell
          key="bedLength"
          title={t('series:header-table.product-selected.head.bedLength.tooltip')}
        >
          <div className="content">
            <TopHeaderTitle>{t('series:header-table.product-selected.head.bedLength.label')}</TopHeaderTitle>
            <TopHeaderUnit key="bedLength">
              {t('series:header-table.product-selected.head.bedLength.unit')}
            </TopHeaderUnit>
          </div>
        </HeaderCell>
      );
    },
    value({ value, formatNumber }) {
      return value ? formatNumber(value, 0, 0) : '-';
    },
  },
  quantity: {
    type: 'integer',
    isEditable: false,
    header({ product, t }) {
      return (
        <HeaderCell
          key="quantity"
          title={t('series:header-table.product-selected.head.quantity.tooltip')}
        >
          <div className="content">
            <TopHeaderTitle>{t('series:header-table.product-selected.head.quantity.label')}</TopHeaderTitle>
            <TopHeaderUnit key="quantity">
              {translateHarvestUnitEnum(t, product.harvestQuantityUnit, 'short')}
            </TopHeaderUnit>
          </div>
        </HeaderCell>
      );
    },
    value(args) {
      const formattedValue = displayValue(args);
      return args.serieOrProduct?.computedQuantity != null
        ? `${formattedValue} / ${displayValue({
            ...args,
            value: args.serieOrProduct.computedQuantity,
          })}`
        : formattedValue;
    },
  },
  harvestQuantityUnit: productsColumnDefinitions.harvestQuantityUnit,
};

export type SeriesHeaderTableProps = {
  isEditingSingleProduct: boolean;
};

export function SeriesHeaderTable({ isEditingSingleProduct }: SeriesHeaderTableProps) {
  const { t } = useTranslation();
  const { displayedColumns, seriesProducts, selectedParentProducts } = useSeriesState();
  const columns = isEditingSingleProduct ? selectedProductColumns[displayedColumns] : noSelectionColumns;
  const columnDefinitions = isEditingSingleProduct ? seriesColumnDefinitions : productsColumnDefinitions;
  const product = isEditingSingleProduct ? seriesProducts[0] : null;
  const parentProduct = isEditingSingleProduct ? selectedParentProducts[product.parentCropItineraryId] : null;
  return (
    <HeaderTableContainer>
      <HeaderTable>
        <HeaderTableHead>
          <tr>
            <TitleCell>
              <div className="cell-content">
                <div className="title">{t('series:header-table.title')}</div>
                <div className="action"></div>
              </div>
            </TitleCell>
            {columns.map((column) => columnDefinitions[column].header({ t, product, parentProduct }))}
            <MarginRightCell></MarginRightCell>
          </tr>
        </HeaderTableHead>
        <HeaderTableBody>
          {seriesProducts.map((product) => (
            <Fragment key={product.parentCropItineraryId}>
              <SeriesHeaderRows
                {...{
                  product,
                  columns,
                  isEditingSingleProduct,
                }}
              />
            </Fragment>
          ))}
          <HeaderEmptyRow columns={columns} />
        </HeaderTableBody>
        <HeaderTableFoot></HeaderTableFoot>
      </HeaderTable>
    </HeaderTableContainer>
  );
}
