import {
  BaseCultureCommands,
  baseCulturesCommandHandler,
  getCoordinatesDependentCulturesCommandHandler,
  ToDraftableHandlers,
} from '@elzeard/common-planning';
import { ProjectPageState } from '../state';
import { PositionPageCommands } from './commands';
import { editionAdapter } from './edition-adapter';
import { planningSpacetimeStateAdapter } from './state-adapter-spacetime';
import { PositionPageState } from './state-full';

const baseHandler = baseCulturesCommandHandler as ToDraftableHandlers<
  ProjectPageState<PositionPageState>,
  BaseCultureCommands
>;
const coordinatesDependentCulturesCommandHandler = getCoordinatesDependentCulturesCommandHandler(
  editionAdapter,
  planningSpacetimeStateAdapter,
);

export const positionPageCommandHandler: ToDraftableHandlers<
  ProjectPageState<PositionPageState>,
  PositionPageCommands
> = {
  ...baseHandler,
  ...coordinatesDependentCulturesCommandHandler,
  setWeekWidth(previousState, command) {
    if (command.weekWidth === previousState.weekWidth) {
      return previousState;
    }
    return {
      ...previousState,
      weekWidth: command.weekWidth,
    };
  },
  toggleCollapsedPlot(previousState, command) {
    return {
      ...previousState,
      collapsedPlots: {
        ...previousState.collapsedPlots,
        [command.plotId]: !previousState.collapsedPlots[command.plotId],
      },
    };
  },
  navigatePlotPeriod(previousState, command, stateDraft) {
    const updatedState = baseHandler.navigatePlotPeriod(previousState, command, stateDraft);
    return {
      ...updatedState,
      ...editionAdapter.updatePlotState(
        updatedState.plots.map((plot) => plot.plot),
        updatedState,
      ),
      // weekWidth: getWeekWidth(plotDisplayPeriod),
    };
  },

  restorePositions(previousState, command, stateDraft) {
    const updatedState = coordinatesDependentCulturesCommandHandler.restorePositions(
      previousState,
      command,
      stateDraft,
    );
    return {
      ...updatedState,
      actions: [...previousState.actions, command],
    };
  },
  dropBedPositionSerie(previousState, command, stateDraft) {
    const updatedState = coordinatesDependentCulturesCommandHandler.dropBedPositionSerie(
      previousState,
      command,
      stateDraft,
    );
    return {
      ...updatedState,
      actions: [...previousState.actions, command],
    };
  },
  dropNoBedPositionSerie(previousState, command, stateDraft) {
    const updatedState = coordinatesDependentCulturesCommandHandler.dropNoBedPositionSerie(
      previousState,
      command,
      stateDraft,
    );
    return {
      ...updatedState,
      actions: [...previousState.actions, command],
    };
  },
  decreaseSurface(previousState, command, stateDraft) {
    const updatedState = coordinatesDependentCulturesCommandHandler.decreaseSurface(previousState, command, stateDraft);
    return {
      ...updatedState,
      actions: [...previousState.actions, command],
    };
  },
};
